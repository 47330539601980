/* You can add global styles to this file, and also import other style files */
:root {
  --fontFamily: "Roboto", sans-serif;
  --headingFontFamily: "Poppins", sans-serif;
  --constructionColor: #ff5e14;
  --restaurantColor: #f39d07;
  --restaurantColor2: #c7a254;
  --travelColor: #ee3429;
  --realEstateColor: #f4b368;
  --realEstateColor2: #170349;
  --creativeColor1: #5d2877;
  --doctorColor: #47e68e;
  --itColor: #008bcf;
  --agencyColor: #fe8f02;
  --seoColor: #7f4aa2;
  --creativeColor2: #4ac728;
  --creativeGradientColor: linear-gradient(to right, #49c628, #53d23c, #5ddd4e, #66e95f, #70f570);
  --creativeOptionalGradientColor: linear-gradient(to right, #fc4a1a, #fd6b12, #fc8712, #faa01f, #f7b733);
  --creativeColor3: #63D0FF;
  --blackColor: #000000;
  --travelGradientColor: linear-gradient(to bottom, #ee3429, #ee3c28, #ee4428, #ed4b27, #ed5127);
  --icoColor: #1cb66f;
  --icoGradientColor: linear-gradient(to right, #03002b, #050e43, #15135c, #2a1474, #43118c);
  --gymColor: #f04923;
  --eventColor1: #ff7946;
  --eventColor2: #ee3429;
  --eventGradientColor: linear-gradient(to bottom, #ee3429, #ee3c28, #ee4428, #ed4b27, #ed5127);
  --autoServicingColor: #00299b;
  --cityColor: #4ac728;
  --cityGradientColor: linear-gradient(to right, #49c628, #53d23c, #5ddd4e, #66e95f, #70f570);
  --cityGradientColor2: linear-gradient(to right, #fc4a1a, #fd6b12, #fc8712, #faa01f, #f7b733);
  --plumbingColor: #FA5B0F;
  --plumbingColor2: #00045f;
  --whiteColor: #ffffff;
  --blackColor: #000000;
  --transition: .5s;
}

body {
  font-family: var(--fontFamily);
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
  transition: var(--transition);
}
a:hover, a:focus {
  text-decoration: none;
}

p:last-child {
  margin-bottom: 0;
}

.d-table {
  width: 100%;
  height: 100%;
}
.d-table-cell {
  vertical-align: middle;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  color: var(--blackColor);
  font-family: var(--headingFontFamily);
}

img {
  max-width: 100%;
}

@keyframes run {
  0% {
    top: -50%;
  }
  100% {
    top: 110%;
  }
}
@keyframes rotateme {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes movescale {
  0% {
    transform: scale(0.6);
  }
  50% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(0.6);
  }
}
@keyframes para {
  100% {
    background-position: -5000px 20%, -800px 95%, 500px 50%, 1000px 100%, 400px 0;
  }
}
@keyframes rotateme {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes animationFramesOne {
  0% {
    transform: translate(0px, 0px) rotate(0deg);
  }
  20% {
    transform: translate(73px, -1px) rotate(36deg);
  }
  40% {
    transform: translate(141px, 72px) rotate(72deg);
  }
  60% {
    transform: translate(83px, 122px) rotate(108deg);
  }
  80% {
    transform: translate(-40px, 72px) rotate(144deg);
  }
  100% {
    transform: translate(0px, 0px) rotate(0deg);
  }
}
@keyframes animationFramesTwo {
  0% {
    transform: translate(0px, 0px) rotate(0deg);
  }
  20% {
    transform: translate(-73px, 1px) rotate(-36deg);
  }
  40% {
    transform: translate(-141px, -72px) rotate(-72deg);
  }
  60% {
    transform: translate(-83px, -122px) rotate(-108deg);
  }
  80% {
    transform: translate(40px, -72px) rotate(-144deg);
  }
  100% {
    transform: translate(0px, 0px) rotate(0deg);
  }
}
@keyframes rotate3d {
  0% {
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
  }
  100% {
    -webkit-transform: rotateY(360deg);
    transform: rotateY(360deg);
  }
}
@keyframes rotate3d {
  0% {
    transform: rotateX(0deg);
  }
  100% {
    transform: rotateX(360deg);
  }
}
@keyframes movebounce {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(0px);
  }
}
@keyframes rotateMe {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes moveBounce {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(0px);
  }
}
@keyframes moveLeftBounce {
  0% {
    transform: translateX(0px);
  }
  50% {
    transform: translateX(20px);
  }
  100% {
    transform: translateX(0px);
  }
}
@keyframes moveScale {
  0% {
    transform: scale(0.6);
  }
  50% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(0.6);
  }
}
@keyframes rotate3d {
  0% {
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
  }
  100% {
    -webkit-transform: rotateY(360deg);
    transform: rotateY(360deg);
  }
}
@keyframes animationFramesOne {
  0% {
    transform: translate(0px, 0px) rotate(0deg);
  }
  20% {
    transform: translate(73px, -1px) rotate(36deg);
  }
  40% {
    transform: translate(141px, 72px) rotate(72deg);
  }
  60% {
    transform: translate(83px, 122px) rotate(108deg);
  }
  80% {
    transform: translate(-40px, 72px) rotate(144deg);
  }
  100% {
    transform: translate(0px, 0px) rotate(0deg);
  }
}
@keyframes imageAnimation {
  0% {
    opacity: 0;
    animation-timing-function: ease-in;
  }
  12.5% {
    opacity: 1;
    animation-timing-function: ease-out;
  }
  25% {
    opacity: 1;
  }
  37.5% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes imageAnimation {
  0% {
    opacity: 0;
    animation-timing-function: ease-in;
  }
  12.5% {
    opacity: 1;
    animation-timing-function: ease-out;
  }
  25% {
    opacity: 1;
  }
  37.5% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
.subscribe-modal {
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  position: fixed;
  visibility: hidden;
  transition: var(--transition);
  background-color: rgba(0, 0, 0, 0.8);
}
.subscribe-modal .subscribe-modal-inner {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  top: 50%;
  width: auto;
  height: auto;
  max-width: 800px;
  background-color: var(--whiteColor);
  border-radius: 5px;
  transform: scale(0.7) translateY(-50%);
  transition: var(--transition);
  padding-top: 70px;
  padding-bottom: 70px;
  margin-left: auto;
  margin-right: auto;
}
.subscribe-modal .subscribe-modal-inner .newsletter-header {
  z-index: 1;
  position: relative;
}
.subscribe-modal .subscribe-modal-inner .newsletter-header .animation-icons {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  width: 50px;
  height: 50px;
  z-index: -1;
  margin-left: auto;
  margin-right: auto;
}
.subscribe-modal .subscribe-modal-inner .newsletter-header .animation-icons .animate-icon {
  color: #e2e2e4;
  position: absolute;
  transition: all 1s ease 0s;
  opacity: 0;
  visibility: hidden;
}
.subscribe-modal .subscribe-modal-inner .newsletter-header .animation-icons .animate-icon:nth-child(1) {
  font-size: 1.5rem;
  left: 0;
  top: 0;
  transform: rotate(20deg);
}
.subscribe-modal .subscribe-modal-inner .newsletter-header .animation-icons .animate-icon:nth-child(2) {
  font-size: 1.5rem;
  left: 0;
  top: 0;
  transform: rotate(-20deg);
}
.subscribe-modal .subscribe-modal-inner .newsletter-header .animation-icons .animate-icon:nth-child(3) {
  font-size: 1.2em;
  left: 0;
  top: 0;
  transform: rotate(-4deg);
  animation-name: rotateme;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.subscribe-modal .subscribe-modal-inner .newsletter-header .animation-icons .animate-icon:nth-child(4) {
  font-size: 2em;
  left: 0;
  top: 0;
  transform: rotate(-20deg);
}
.subscribe-modal .subscribe-modal-inner .newsletter-header .animation-icons .animate-icon:nth-child(5) {
  font-size: 2em;
  left: 0;
  top: 0;
  transform: rotate(-7deg);
}
.subscribe-modal .subscribe-modal-inner .newsletter-header .animation-icons .animate-icon:nth-child(6) {
  font-size: 1.5em;
  left: 0;
  top: 0;
  transform: rotate(22deg);
}
.subscribe-modal .subscribe-modal-inner .newsletter-header .animation-icons .animate-icon:nth-child(7) {
  font-size: 1.5rem;
  right: 0;
  top: 0;
  transform: rotate(20deg);
}
.subscribe-modal .subscribe-modal-inner .newsletter-header .animation-icons .animate-icon:nth-child(8) {
  font-size: 1.5rem;
  right: 0;
  top: 0;
  transform: rotate(-20deg);
}
.subscribe-modal .subscribe-modal-inner .newsletter-header .animation-icons .animate-icon:nth-child(9) {
  font-size: 1.2em;
  right: 0;
  top: 0;
  transform: rotate(-4deg);
  animation: movescale 3s linear infinite;
}
.subscribe-modal .subscribe-modal-inner .newsletter-header .animation-icons .animate-icon:nth-child(10) {
  font-size: 2em;
  right: 0;
  top: 0;
  transform: rotate(-20deg);
}
.subscribe-modal .subscribe-modal-inner .newsletter-header .animation-icons .animate-icon:nth-child(11) {
  font-size: 2em;
  right: 0;
  top: 0;
  transform: rotate(-7deg);
}
.subscribe-modal .subscribe-modal-inner .newsletter-header .animation-icons .animate-icon:nth-child(12) {
  font-size: 1.5em;
  right: 0;
  top: 0;
  transform: rotate(22deg);
}
.subscribe-modal .subscribe-modal-inner h2 {
  margin-bottom: 0;
  margin-top: 30px;
  font-size: 30px;
  font-weight: 600;
}
.subscribe-modal .subscribe-modal-inner p {
  color: #555555;
  font-size: 14px;
  max-width: 520px;
  line-height: 1.8;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0;
  margin-top: 12px;
}
.subscribe-modal .subscribe-modal-inner form {
  position: relative;
  max-width: 500px;
  box-shadow: 0 5px 28.5px 1.5px rgba(149, 152, 200, 0.2);
  border-radius: 30px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 35px;
}
.subscribe-modal .subscribe-modal-inner form .input-newsletter {
  display: inline-block;
  width: 100%;
  background-color: var(--whiteColor);
  border: none;
  outline: 0;
  transition: var(--transition);
  border-radius: 30px;
  height: 55px;
  font-size: 14px;
  padding-left: 20px;
}
.subscribe-modal .subscribe-modal-inner form button {
  position: absolute;
  right: 0;
  top: 0;
  height: 55px;
  border-radius: 0 30px 30px 0;
  border: none;
  background-color: var(--blackColor);
  outline: 0;
  color: var(--whiteColor);
  transition: var(--transition);
  font-size: 16px;
  font-weight: 600;
  padding-left: 20px;
  padding-right: 20px;
}
.subscribe-modal .subscribe-modal-inner form button:hover {
  color: var(--whiteColor);
  background-color: var(--constructionColor);
}
.subscribe-modal .subscribe-modal-inner form #validator-newsletter {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -35px;
  text-align: center;
  font-size: 14px;
  margin-left: auto;
  margin-right: auto;
}
.subscribe-modal .subscribe-modal-inner form .validation-danger {
  color: red;
}
.subscribe-modal .subscribe-modal-inner .close-btn {
  z-index: 2;
  position: absolute;
  display: inline-block;
  right: -30px;
  top: -34px;
  font-size: 25px;
  transition: var(--transition);
  color: var(--whiteColor);
  opacity: 0.66;
  cursor: pointer;
}
.subscribe-modal .subscribe-modal-inner .close-btn:hover {
  opacity: 1;
}
.subscribe-modal.active {
  opacity: 1;
  visibility: visible;
}
.subscribe-modal.active .subscribe-modal-inner {
  transform: scale(1) translateY(-50%);
}
.subscribe-modal.active .subscribe-modal-inner .newsletter-header .animation-icons .animate-icon {
  opacity: 1;
  visibility: visible;
}
.subscribe-modal.active .subscribe-modal-inner .newsletter-header .animation-icons .animate-icon:nth-child(1) {
  left: -120px;
  top: -40px;
}
.subscribe-modal.active .subscribe-modal-inner .newsletter-header .animation-icons .animate-icon:nth-child(2) {
  left: -110px;
  top: 50px;
}
.subscribe-modal.active .subscribe-modal-inner .newsletter-header .animation-icons .animate-icon:nth-child(3) {
  left: -132px;
  top: 14px;
}
.subscribe-modal.active .subscribe-modal-inner .newsletter-header .animation-icons .animate-icon:nth-child(4) {
  left: -190px;
  top: -15px;
}
.subscribe-modal.active .subscribe-modal-inner .newsletter-header .animation-icons .animate-icon:nth-child(5) {
  left: -177px;
  top: 42px;
}
.subscribe-modal.active .subscribe-modal-inner .newsletter-header .animation-icons .animate-icon:nth-child(6) {
  left: -90px;
  top: 8px;
}
.subscribe-modal.active .subscribe-modal-inner .newsletter-header .animation-icons .animate-icon:nth-child(7) {
  right: -120px;
  top: -40px;
}
.subscribe-modal.active .subscribe-modal-inner .newsletter-header .animation-icons .animate-icon:nth-child(8) {
  right: -110px;
  top: 50px;
}
.subscribe-modal.active .subscribe-modal-inner .newsletter-header .animation-icons .animate-icon:nth-child(9) {
  right: -132px;
  top: 14px;
}
.subscribe-modal.active .subscribe-modal-inner .newsletter-header .animation-icons .animate-icon:nth-child(10) {
  right: -190px;
  top: -15px;
}
.subscribe-modal.active .subscribe-modal-inner .newsletter-header .animation-icons .animate-icon:nth-child(11) {
  right: -177px;
  top: 42px;
}
.subscribe-modal.active .subscribe-modal-inner .newsletter-header .animation-icons .animate-icon:nth-child(12) {
  right: -90px;
  top: 8px;
}

.sidebar-modal {
  top: 0;
  right: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  position: fixed;
  overflow: hidden;
  visibility: hidden;
  transition: var(--transition);
  background-color: rgba(0, 0, 0, 0.8);
}
.sidebar-modal .sidebar-modal-inner {
  top: 0;
  z-index: 1;
  width: 40%;
  height: 100%;
  right: -100%;
  transition: 0.7s;
  position: absolute;
  overflow-y: scroll;
  background-color: var(--whiteColor);
  padding-top: 60px;
  padding-bottom: 60px;
  padding-left: 60px;
  padding-right: 60px;
}
.sidebar-modal .sidebar-modal-inner .close-btn {
  display: inline-block;
  position: absolute;
  right: 35px;
  top: 20px;
  font-size: 25px;
  transition: var(--transition);
  color: var(--blackColor);
  opacity: 0.66;
  cursor: pointer;
}
.sidebar-modal .sidebar-modal-inner .close-btn:hover {
  opacity: 1;
}
.sidebar-modal .about-area {
  margin-bottom: 40px;
}
.sidebar-modal .about-area .title h2 {
  margin-bottom: 0;
  font-size: 25px;
  font-weight: 700;
}
.sidebar-modal .about-area .title p {
  font-size: 14px;
  margin-top: 12px;
  line-height: 1.8;
  color: #727695;
}
.sidebar-modal .contact-area .title {
  margin-bottom: 25px;
}
.sidebar-modal .contact-area .title h2 {
  margin-bottom: 0;
  font-size: 25px;
  font-weight: 700;
}
.sidebar-modal .contact-area .contact-form form .form-control {
  height: 50px;
  border: none;
  box-shadow: 0 5px 28.5px 1.5px rgba(149, 152, 200, 0.15);
  background-color: var(--whiteColor);
  font-size: 14px;
  padding-left: 15px;
  color: var(--blackColor);
}
.sidebar-modal .contact-area .contact-form form textarea.form-control {
  height: auto;
  padding-top: 15px;
}
.sidebar-modal .contact-area .contact-form form button {
  margin-top: 10px;
  border: none;
  background-color: var(--blackColor);
  outline: 0;
  color: var(--whiteColor);
  transition: var(--transition);
  border-radius: 30px;
  box-shadow: 0 5px 28.5px 1.5px rgba(0, 0, 0, 0.3);
  font-size: 16px;
  font-weight: 600;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 11px;
  padding-bottom: 11px;
}
.sidebar-modal .contact-area .contact-form form button:hover {
  color: var(--whiteColor);
  background-color: var(--constructionColor);
}
.sidebar-modal .contact-area .contact-info {
  margin-top: 50px;
}
.sidebar-modal .contact-area .contact-info .contact-info-content h3 {
  margin-bottom: 15px;
  font-size: 20px;
  font-weight: 700;
}
.sidebar-modal .contact-area .contact-info .contact-info-content h2 {
  margin-bottom: 0;
  font-size: 28px;
  font-weight: 700;
}
.sidebar-modal .contact-area .contact-info .contact-info-content h2 a {
  display: inline-block;
  color: var(--constructionColor);
}
.sidebar-modal .contact-area .contact-info .contact-info-content h2 a:hover {
  color: var(--blackColor);
}
.sidebar-modal .contact-area .contact-info .contact-info-content h2 a:not(:first-child) {
  color: var(--blackColor);
}
.sidebar-modal .contact-area .contact-info .contact-info-content h2 a:not(:first-child):hover {
  color: var(--constructionColor);
}
.sidebar-modal .contact-area .contact-info .contact-info-content h2 span {
  display: block;
  color: #57647c;
  margin-top: 8px;
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 500;
}
.sidebar-modal .contact-area .contact-info .contact-info-content .social {
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 0;
  margin-top: 20px;
}
.sidebar-modal .contact-area .contact-info .contact-info-content .social li {
  display: inline-block;
  margin: 0 4px;
}
.sidebar-modal .contact-area .contact-info .contact-info-content .social li a {
  width: 35px;
  height: 35px;
  line-height: 34px;
  border: 1px solid #eeeeee;
  border-radius: 50%;
  color: var(--blackColor);
  display: block;
  text-align: center;
}
.sidebar-modal .contact-area .contact-info .contact-info-content .social li a i {
  font-size: 14px;
}
.sidebar-modal .contact-area .contact-info .contact-info-content .social li a:hover {
  color: var(--whiteColor);
  border-color: var(--constructionColor);
  background-color: var(--constructionColor);
}
.sidebar-modal.active {
  opacity: 1;
  visibility: visible;
}
.sidebar-modal.active .sidebar-modal-inner {
  right: 0;
}

@media only screen and (max-width: 767px) {
  .sidebar-modal .sidebar-modal-inner {
    width: 100%;
    padding: 30px;
  }
  .sidebar-modal .sidebar-modal-inner .close-btn {
    right: 20px;
    top: 14px;
    font-size: 20px;
  }
  .sidebar-modal .about-area {
    margin-bottom: 30px;
  }
  .sidebar-modal .about-area .title h2 {
    font-size: 20px;
  }
  .sidebar-modal .about-area .title p {
    font-size: 13px;
    margin-top: 10px;
    line-height: 1.8;
  }
  .sidebar-modal .contact-area .title {
    margin-bottom: 20px;
  }
  .sidebar-modal .contact-area .title h2 {
    font-size: 20px;
  }
  .sidebar-modal .contact-area .contact-form {
    max-width: 100%;
  }
  .sidebar-modal .contact-area .contact-info {
    margin-top: 35px;
  }
  .sidebar-modal .contact-area .contact-info .contact-info-content h3 {
    font-size: 17px;
    line-height: 1.4;
  }
  .sidebar-modal .contact-area .contact-info .contact-info-content h2 {
    font-size: 22px;
  }
  .sidebar-modal .contact-area .contact-info .contact-info-content h2 span {
    font-size: 13px;
  }
  .subscribe-modal .subscribe-modal-inner {
    max-width: 100%;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 0;
    overflow-y: scroll;
    transform: scale(0.7) translateY(0);
    padding-top: 40px;
    padding-bottom: 40px;
    padding-left: 15px;
    padding-right: 15px;
  }
  .subscribe-modal .subscribe-modal-inner h2 {
    font-size: 18px;
    margin-top: 25px;
  }
  .subscribe-modal .subscribe-modal-inner p {
    max-width: 100%;
    line-height: 1.8;
    margin-top: 10px;
    font-size: 13px;
  }
  .subscribe-modal .subscribe-modal-inner .close-btn {
    right: 15px;
    top: 15px;
    font-size: 20px;
    color: var(--blackColor);
  }
  .subscribe-modal .subscribe-modal-inner .newsletter-header .animation-icons {
    display: none;
  }
  .subscribe-modal .subscribe-modal-inner .newsletter-header img {
    width: 100px;
  }
  .subscribe-modal .subscribe-modal-inner form {
    max-width: 100%;
    border-radius: 0;
    margin-top: 30px;
    box-shadow: unset;
  }
  .subscribe-modal .subscribe-modal-inner form .input-newsletter {
    box-shadow: 0 5px 28.5px 1.5px rgba(149, 152, 200, 0.2);
  }
  .subscribe-modal .subscribe-modal-inner form button {
    position: relative;
    margin-top: 15px;
    height: auto;
    border-radius: 30px;
    font-size: 15px;
    box-shadow: 0 5px 28.5px 1.5px rgba(149, 152, 200, 0.2);
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 11px;
    padding-bottom: 11px;
  }
  .subscribe-modal .subscribe-modal-inner form #validator-newsletter {
    bottom: -30px;
    font-size: 13px;
  }
  .subscribe-modal .subscribe-modal-content {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    margin: 0 auto;
    padding-left: 15px;
    padding-right: 15px;
  }
  .subscribe-modal.active .subscribe-modal-inner {
    transform: scale(1) translateY(0);
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .subscribe-modal .subscribe-modal-content {
    padding-left: 40px;
    padding-right: 40px;
  }
  .subscribe-modal .subscribe-modal-content h2 {
    font-size: 24px;
  }
  .subscribe-modal .subscribe-modal-content p {
    font-size: 14px;
  }
  .subscribe-modal .subscribe-modal-content .close-btn {
    right: 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sidebar-modal .sidebar-modal-inner {
    width: 85%;
    padding-top: 40px;
    padding-bottom: 40px;
    padding-left: 40px;
    padding-right: 40px;
  }
  .sidebar-modal .about-area .title h2 {
    font-size: 24px;
  }
  .sidebar-modal .contact-area .title h2 {
    font-size: 24px;
  }
  .subscribe-modal .subscribe-modal-inner h2 {
    font-size: 25px;
  }
  .subscribe-modal .subscribe-modal-inner .close-btn {
    right: 25px;
    top: 20px;
    font-size: 25px;
    color: var(--blackColor);
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sidebar-modal .sidebar-modal-inner {
    width: 60%;
  }
}